.programm

	&__cont
		position: relative
		margin: 0 0 0

		&-bg

			img
				display: block
				width: 100%

		.wrapper
			position: absolute 0
			display: flex
			flex-direction: column

.programm
	flex: 1 1 auto
	// padding: 30px 0
	display: flex
	flex-direction: column
	justify-content: space-around
	justify-content: space-evenly

	&__list
		// margin: 180px 0 0

.for-who

	&__title
		text-align: center
		font-size: 30px
		margin-bottom: 50px

.for-items
	display: flex
	flex-wrap: wrap
	margin: 0 0 -30px -30px

	&__item
		width: calc((100% - 30px * 2) / 2)
		margin: 0 0 30px 30px

.for
	display: flex
	align-items: center

	&__img
		flex: none
		margin-right: 50px

		img
			display: block

	&__text
		max-width: 300px
		line-height: calc(24 / 16)

.programm-list
	display: flex
	flex-wrap: wrap
	justify-content: space-around

	&__item
		width: 25%
		padding: 25px 24px
		display: flex
		flex-direction: column
		border: 1px solid #394a79

		// &:not(:nth-child(3n + 1)),
		// &:first-child
		// 	border-right: 1px solid #394a79

		// &:nth-child(odd)

		// 	.programm-item

		// 		&__img

		// 			&:after
		// 				right: -1px

.programm-item
	text-align: center
	flex: 1 1 auto
	display: flex
	flex-direction: column

	&__img
		flex: none
		// margin-bottom: 10px
		// padding-bottom: 10px
		position: relative
		// padding: 0px
		padding: 25px 24px
		margin: -25px -24px
		background: #c03434
		color: #fff

		img
			position: relative
			z-index: 1

		// &:after
		// 	content: ""
		// 	position: absolute 0
		// 	background: linear-gradient(to left, #ea7760, #ef5050) 

	&__title
		margin-bottom: 3px

	&__text
		margin: 75px 0 0
		font-size: 14px
		line-height: 1.6
		flex: 1 1 auto

		padding-bottom: 30px
		margin-bottom: 30px
		position: relative

		&:after
			content: ""
			position: absolute * * 0 calc(50% - 20px)
			size: 40px 2px
			background: #fe541e


@media screen and (max-width: 1550px)
	.programm
		padding: 30px 0 40px

		&__list
			margin: 40px 0 0

	.for-who

		&__title
			margin-bottom: 30px


@media screen and (max-width: 1400px)
	.programm

		&__cont
			margin: 40px 0 0


@media screen and (max-width: 1300px)
	.programm__cont-bg

		img
			min-height: 780px
			object-fit: cover
			object-position: center

@media screen and (max-width: 1000px)
	.programm

		&__cont
			// background: #0a2146

			&-bg
				// display: none

			.wrapper
				// position: static

@media screen and (max-width: 1000px) and (min-width: 660px)

	.programm-list
		border-bottom: none

		&__item
			width: 50%
			border: none !important
			border-bottom: 1px solid #394a79 !important

			&:nth-child(odd)
				border-right: 1px solid #394a79 !important

@media screen and (max-width: 660px)
	.for-who__title
		margin-bottom: 25px
		font-size: 22px

	.for-items
		margin: 0

		&__item
			width: 100%
			margin: 0

			&:not(:first-child)
				margin: 20px 0 0

	.for

		&__img
			margin-right: 20px
			width: 70px
			text-align: center

			img
				display: inline-block

	.for-who

		&__title
			font-size: 22px

	.programm-list
		border-bottom: none

		&__item
			width: 100%
			border-right: none !important
			border-bottom: 1px solid #394a79

	.programm__cont
		&-bg
			display: none

		.wrapper
			position: static

