.advantages

	&__cont
		margin: 50px 0 0

.advantages
	display: flex
	flex-wrap: wrap

	&__item
		width: 25%
		// border: 1px solid #eeeeee
		display: flex
		flex-direction: column

.advantage
	flex: 1 1 auto
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 48px 30px 61px

	&__img
		flex: none

		&:not(:last-child)
			margin-bottom: 27px

		img
			display: block

	&__text
		text-align: center
		flex: 1 1 auto
		line-height: 1.6
		font-size: 1.14286rem
		padding-bottom: 25px
		position: relative

		&:after
			position: absolute * * 0 calc(50% - 20px)
			size: 40px 2px
			background: #fe541e
			content: ""



@media screen and (max-width: 1400px)
	.advantages

		&__cont
			margin: 40px 0 0


@media screen and (max-width: 1200px)
	.advantage
		padding: 28px 20px 35px


@media screen and (max-width: 1000px) and (min-width: 660px)
	.advantages

		&__item
			width: 50%

@media screen and (max-width: 660px)
	.advantages

		&__item
			width: 100%