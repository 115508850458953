@import "../css/common.css"

@import "_vars.sss"
@import "_base.sss"
@import "_text-styles.sss"
@import "_form-elements.sss"
// @import "_title-block.sss"
// @import "_paginator.sss"

@import "_main-page.sss"
@import "_main-advantages.sss"
@import "_main-orgs.sss"
@import "_main-programm.sss"
@import "_main-how.sss"

@media screen and (max-width: 660px)
	.wrapper
		padding-left: 21px !important
		padding-right: 21px !important

.advantage, .programm-item, .person, .for, .how-item
	opacity: 0

#content
	overflow-x: hidden

.about-projects
	display: flex

	&-bg
		img
			width: 100%



	&__title
		font-size: rem(34)
		margin-bottom: 55px 

	&__cont
		position: relative
		// overflow-x: hidden

		> .wrapper
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			z-index: 10
			display: flex
			flex-direction: column
			justify-content: center

	&__info
		width: calc(100% - 280px)
		max-width: 610px

	&__slider
		width: 260px
		margin-right: auto
		display: flex
		flex-direction: column
		justify-content: center


		&-item
			display: flex !important
			flex-direction: column
			align-items: center

		&-text
			text-align: center
			font-size: 15px
			margin-top: 20px

		&-arrow
			display: flex
			justify-content: center
			margin-top: 40px


		.slick
			&-arrow
				position: relative
				size: 56px
				border-radius: 50%
				background: #e7e3e3


				&:before
					content: ''
					display: block
					position: absolute
					top: calc(50% - 6px)
					left: calc(50% - 6px)
					size: 12px
					border-top: 1px solid #000
					border-right: 1px solid #000
					transform: rotate(45deg)

			&-prev
				transform: rotate(-180deg)
				margin-right: 40px
			// &-next 



.events
	&__cont
		margin: 90px 0 115px

	&-title
		font-size: rem(34)
		margin-bottom: 55px
		text-align: center

	&__list
		display: flex
		flex-wrap: wrap
		margin: 0 0 -30px -30px

	&__item
		width: calc((100% - 30px * 2) / 2)
		margin: 0 0 30px 30px
		background: #f9f9f9
		transition: background .3s ease
		padding: 45px 60px

		&:hover
			background: #c03434

			.events__item
				&-title, &-link
					color: #fff 

				&-link
					a
						color: #fff

				&-date
					border-color: #fff



		&-title
			font-size: rem(26)
			transition: color .3s ease

		&-link
			font-size: rem(15)
			color: #c03434

		&-date
			display: inline-block
			padding: 5px 15px
			background: #c03434
			border-radius: 5px
			color: #fff
			font-size: rem(15)
			border: 1px solid transparent


		&-top
			display: flex 
			justify-content: space-between

		&-bot
			margin-top: 25px


.partners
	&__cont
		background: #c03434
		color: #fff
		padding: 80px 0 100px
		margin-bottom: 80px


		.top-block__text-sponsors
			margin-top: 0

	&__title
		margin-bottom: 35px
		text-align: center
		font-size: rem(34)

.bottom
	display: flex
	flex-direction: column
	align-items: center

	&-title
		text-align: center
		font-size: rem(26)

	&-cont
		position: relative

		.dev
			text-align: center

		&-bg
			img
				display: block
				width: 100%

		> .wrapper
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			position: absolute
			display: flex
			flex-direction: column
			justify-content: center

.dev
	font-size: rem(15)
	color: #b0b0b0
.soc
	margin: 55px 0 75px

	&-item
		size: 48px
		border-radius: 5px
		border: 1px solid #c03434
		display: inline-block
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center

		&:not(:first-child)
			margin-left: 40px



@media screen and (max-width: 1500px)
	
	.soc
		margin: 20px 0 30px

	.how__cont
		padding-bottom: 70px

		&-cont
			margin: 70px 0 0

	.events__cont
		margin: 65px 0 85px

@media screen and (max-width: 1400px)

	.top-block__text
		padding: 75px 0 0

	.about-projects
		&__slider
			margin-right: 30px

		&__info
			max-width: 850px

		&__title
			margin-bottom: 15px


		&-bg
			img
				min-height: 905px
				object-fit: cover
				object-position: center

@media screen and (max-width: 1200px)
	
	.about-projects-bg 
		img
			min-height: 810px

	.events__cont
		margin: 22px 0 45px

	.programm__cont-bg 
		img
			min-height: 840px

	.partners
		&__cont
			padding: 50px 0 45px
			margin-bottom: 0

		&__title
			margin-bottom: 20px


@media screen and (max-width: 1000px)

	#content
		overflow-x: visible

	.registration
		padding: 30px
		width: 330px

	.top-block__text
		padding: 0 0 0

	.events
		&-title
			font-size: 30px
			margin-bottom: 25px

		&__item
			width: calc((100% - 50px)/2)
			margin: 0 0 25px 25px
			padding: 25px

			&-top
				flex-direction: column

			&-title
				font-size: 1.4rem

	.programm-list
		border-left: 1px solid #000
		border-right: 1px solid #000


	.sponsors
		flex-wrap: wrap
		margin: 0 0 -20px -20px
		justify-content: center

		&__item
			width: calc((100% - 20px * 2) / 2)
			margin: 0 0 20px 20px
			display: flex
			justify-content: center

	.bottom
		&-title
			font-size: 1.4rem

		&-cont-bg 
			img
				min-height: 250px
				object-fit: cover
				object-position: center


@media screen and (max-width: 667px)

	.about-projects
		flex-direction: column

		&-bg
			display: none

		&__cont
			> .wrapper
				position: static

		&__slider
			width: 100%
			margin-right: 0

		&__info
			width: 100%

	.about-projects__title
		font-size: 30px

	.events
		&__item
			width: 100%

		&-title
			font-size: 22px

	.sponsors
		&__item
			width: 100%

	.partners
		&__cont
			padding: 35px 0


		&__title
			font-size: 22px

	.bottom-title
		font-size: 17px

	.how__slider
		margin: 0 -22px

	.how__cont
		padding-bottom: 35px

	.programm-list
		border-left: 0

	.soc-item:not(:first-child)
		margin-left: 20px
