.orgs
	text-align: center

	&__cont
		margin: 40px 0 0

	&__title
		margin-bottom: 40px
		font-size: 2.14286rem

.orgs-list
	display: flex
	justify-content: center
	flex-wrap: wrap
	margin: 0 0 -50px -50px
	
	&__item
		display: flex
		flex-direction: column
		width: calc((100% - 50px * 4) / 4)
		margin: 0 0 50px 50px

.person
	flex: 1 1 auto
	display: flex
	flex-direction: column
	align-items: center
	line-height: 1.6

	&__img
		margin-bottom: 38px

		img
			display: block
			border-radius: 50%
			size: 110px

	&__name
		font-weight: bold
		font-size: 1.14286rem
		margin-bottom: 5px

	&__whois
		color: #979aa7
		font-size: 16px
		// border-bottom: 1px solid #eee
		// padding-bottom: 12px
		// margin-bottom: 13px

	&__text
		font-size: 0.92857rem
		flex: 1 1 auto


@media screen and (max-width: 1400px)
	.orgs

		&__cont
			margin: 40px 0 0


@media screen and (max-width: 1000px) and (min-width: 660px)
	.orgs-list
		margin: 0 0 -20px -20px

		&__item
			width: calc((100% - 20px * 2) / 2)
			margin: 0 0 20px 20px

@media screen and (max-width: 660px)
	.orgs-list
		margin: 0

		&__item
			width: 100%
			margin: 0

			&:not(:first-child)
				margin: 25px 0 0

	.orgs

		&__title
			font-size: 22px

	.person

		&__img
			margin-bottom: 10px