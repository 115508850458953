.top-block
	position: relative

	&__bg

		img
			display: block
			width: 100%

	&__text
		position: absolute 0
		color: #fff
		display: flex
		align-items: center
		padding: 100px 0 0

		.wrapper
			width: 100%

		&-sponsors
			margin: 110px 0 0

	&--bot
		margin: 105px 0 0

		.top-block

			&__text
				padding: 0
				flex-direction: column

				.wrapper
					flex: 1 1 auto
					padding: 70px 0 100px
					display: flex
					flex-direction: column
					justify-content: space-between

.top-text
	display: flex
	align-items: center
	justify-content: space-between

.top-titles

	&__title
		font-size: 70px
		font-weight: bold
		line-height: calc(90 / 70)

	&__subtitle
		font-weight: 400
		margin: 20px 0 0
		line-height: calc(24 / 16)
		max-width: 450px

.registration
	color: #000 #fff
	padding: 50px 63px 62px
	width: 440px

	&__title
		font-size: 20px
		margin-bottom: 12px

	&__text
		font-size: 14px
		line-height: calc(22 / 14)
		color: #979aa7

		&:not(:last-child)
			margin-bottom: 27px

.sponsors
	display: flex
	align-items: center
	justify-content: space-between

.top-contacts
	font-size: 15px
	line-height: 1.6

	a
		color: #fff


@media screen and (max-width: 1550px)
	.top-block

		&--bot
			margin: 40px 0 0

			.top-block__text 

				.wrapper
					padding: 40px 20px 50px

		&__text
			padding: 40px 0 0

			&-sponsors
				margin: 40px 0 0


@media screen and (max-width: 1300px)
	
	.top-block

		.wrapper
			box-sizing: border-box

	.top-titles

		&__title
			font-size: 55px

@media screen and (max-width: 1200px)
	
	.top-block

		&__bg

			img
				min-height: 465px
				object-fit: cover
				object-position: center

@media screen and (max-width: 660px)
	
	.top-block
		// background: #0a2146

		&__bg
			// display: none

		&__text
			// position: static
			padding: 25px 0 15px

			&-sponsors
				margin: 30px 0 0

	.top-text
		display: block

		&__left

			&:not(:last-child)
				margin-bottom: 30px

	.top-titles

		&__title
			font-size: 22px

		&__subtitle
			font-size: 14px
			margin: 10px 0 0

	// .registration
	// 	padding: 0
	// 	width: auto
	// 	background: none
	// 	color: #fff

	// 	&__text
	// 		color: inherit

	// 	&__title
	// 		font-size: 18px

	.sponsors
		flex-wrap: wrap

		&__item
			width: 50%
			padding: 10px
			// text-align: center

			img
				max-width: 100%

	.top-contacts
		margin-bottom: 20px




